import { render, staticRenderFns } from "./DetailAttendanceCard.vue?vue&type=template&id=8e0cb4ac&scoped=true&"
import script from "./DetailAttendanceCard.vue?vue&type=script&lang=js&"
export * from "./DetailAttendanceCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e0cb4ac",
  null
  
)

export default component.exports