var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"label":"ชื่อ","validations":[
        {text: 'required!', value: _vm.$v.formData.name.$dirty && !_vm.$v.formData.name.required}
      ]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('sgv-input-number',{staticClass:"col-6 col-sm-4",attrs:{"label":"ระดับ (1-3)","validations":[
        {text: 'จำนวนไม่อยู่ในช่วง', value: _vm.$v.formData.level.$dirty && !_vm.$v.formData.level.between}
      ]},model:{value:(_vm.formData.level),callback:function ($$v) {_vm.$set(_vm.formData, "level", $$v)},expression:"formData.level"}}),_c('sgv-input-number',{staticClass:"col-6 col-sm-4",attrs:{"label":"อายุงาน (เดือน)","validations":[
        {text: 'จำนวนไม่อยู่ในช่วง', value: _vm.$v.formData.monthExperience.$dirty && !_vm.$v.formData.monthExperience.greater}
      ]},model:{value:(_vm.formData.monthExperience),callback:function ($$v) {_vm.$set(_vm.formData, "monthExperience", $$v)},expression:"formData.monthExperience"}}),_c('sgv-input-text',{staticClass:"col-6 col-sm-4",attrs:{"label":"ทบทวน (cron)"},model:{value:(_vm.formData.cron),callback:function ($$v) {_vm.$set(_vm.formData, "cron", $$v)},expression:"formData.cron"}}),_c('sgv-input-textarea',{staticClass:"col-12",attrs:{"label":"รายละเอียด","validations":[
        {text: 'required!', value: _vm.$v.formData.description.$dirty && !_vm.$v.formData.description.required}
      ]},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('sgv-input-check',{attrs:{"label":"บังคับ","inline":""},model:{value:(_vm.formData.isRequired),callback:function ($$v) {_vm.$set(_vm.formData, "isRequired", $$v)},expression:"formData.isRequired"}})],1)]),(_vm.item)?_c('DetailAbilityCardFormPaper',{staticClass:"mb-4",attrs:{"formData":_vm.item,"jobDescriptionType":_vm.jobDescriptionType,"templateType":_vm.templateType}}):_vm._e(),(!_vm.item && _vm.$auth.hasRole(("jobDescription:" + (this.jobDescriptionType) + ":add")))?_c('button',{staticClass:"btn btn-success mr-2",on:{"click":_vm.createData}},[_vm._v(" เพิ่ม ")]):_vm._e(),(_vm.item && _vm.$auth.hasRole(("jobDescription:" + (this.jobDescriptionType) + ":add")))?_c('button',{staticClass:"btn btn-warning mr-2",on:{"click":_vm.updateData}},[_vm._v(" แก้ไข ")]):_vm._e(),_c('button',{staticClass:"btn btn-primary ",on:{"click":_vm.clearFormData}},[_vm._v(" ยกเลิก ")]),(_vm.item && _vm.$auth.hasRole(("jobDescription:" + (this.jobDescriptionType) + ":add")))?_c('button',{staticClass:"btn btn-danger float-right",on:{"click":_vm.destroyData}},[_vm._v(" ลบ ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }