<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">

    <div class="row">
      <div class="col-12">
        <sgv-form :method.sync="method" :options="options">
          <DetailForm
            :formData="formData"
            :jobDescriptionType="jobDescriptionType"
            :templateType="templateType"
            :method="method"
            :v="$v">
          </DetailForm>
        </sgv-form>
      </div>
    </div>

    <HrDivider :options="tabs" v-model="selectedTab"></HrDivider>

    <keep-alive>
      <component
        v-if="jobDescriptionId > 0"
        :is="selectedTab"
        :jobDescriptionId="jobDescriptionId"
        :jobDescriptionType="jobDescriptionType"
        :templateType="templateType"
        :method="method"
        :formData="formData">
      </component>
    </keep-alive>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import DetailForm from './DetailForm.vue'
import DetailAbility from './DetailAbility.vue'
import DetailAttendance from './DetailAttendance.vue'
import {
  DETAIL_JOBDESCRIPTION,
  CREATE_JOBDESCRIPTION,
  UPDATE_JOBDESCRIPTION,
  DESTROY_JOBDESCRIPTION,
  APPROVE_JOBDESCRIPTION,
  ROLLBACK_JOBDESCRIPTION
} from './graph'

export default {
  mixins: [retainMixin],
  metaInfo () {
    if (this.formData.code) {
      return {title: `${this.formData.code}-${this.formData.position}`}
    }
    return {title: `${this.title}`}
  },
  props: {
    jobDescriptionId: {
      type: Number,
      required: true
    },
    jobDescriptionType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      method: 'info',
      listView: `ContactJobDescription${this.$form.capitalize(this.jobDescriptionType)}List`,
      formData: {
        code: '',
        position: '',
        department: '',
        commander: '',
        subordinate: '',
        age: '',
        gender: '',
        education: '',
        discipline: '',
        skill: '',
        etc: '',
        responsibility: '',
        training: '',
        isActive: true,
        isJobApplication: false,
        isJobTraining: false,
        isJobEvaluation: false,
        parentId: null,
        createdAt: null,
        createdBy: null,
        createdUser: null,
        approvedAt: null,
        approvedBy: null,
        approvedUser: null,
        papers: []
      },
      tabs: [
        {text: 'ความสามารถ', value: 'DetailAbility'},
        {text: 'แผนกิจกรรม', value: 'DetailAttendance'},
      ],
      selectedTab: 'DetailAttendance',
      rKey: `ContactJobDescription${this.$form.capitalize(this.jobDescriptionType)}Detail`,
      rFields: ['selectedTab']
    }
  },
  validations: {
    formData: {
      code: { required },
      position: { required },
    }
  },
  computed: {
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || this.formData.approvedAt || !this.$auth.hasRole(`jobDescription:${this.jobDescriptionType}:add`)},
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || this.formData.approvedAt || !this.$auth.hasRole(`jobDescription:${this.jobDescriptionType}:add`)},
        {
          text: 'อนุมัติ',
          variant: 'warning',
          method: 'approve',
          func: this.approveData,
          disabled: this.method === 'add' || this.formData.approvedAt || !this.$auth.hasRole(`jobDescription:${this.jobDescriptionType}:approve`)},
        {
          text: 'ยกเลิกอนุมัติ',
          variant: 'info',
          method: 'rollback',
          func: this.rollbackData,
          disabled: this.method === 'add' || !this.formData.approvedAt || !this.$auth.hasRole(`jobDescription:${this.jobDescriptionType}:approve`)},
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'},
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_JOBDESCRIPTION(this.templateType),
        variables: {
          jobDescriptionType: this.jobDescriptionType,
          jobDescriptionId: this.jobDescriptionId
        }
      })
      .then(res => {
        this.setFormData(res.data.jobDescription)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
      .finally(() => {
        this.isLoaded = true
      })
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      const obj = {...v}
      const removes = [
        'createdAt', 'createdBy', 'createdUser',
        'approvedAt', 'approvedBy', 'approvedUser',
        'papers'
      ]
      removes.forEach(x => delete obj[x])
      return obj
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: CREATE_JOBDESCRIPTION(this.templateType),
        variables: {
          jobDescriptionType: this.jobDescriptionType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            jobDescriptionId: res.data.createJobDescription.id
          },
          query: {
            ...this.$route.query,
            redirect: this.listView
          }
        })
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: UPDATE_JOBDESCRIPTION(this.templateType),
        variables: {
          jobDescriptionType: this.jobDescriptionType,
          jobDescriptionId: this.jobDescriptionId,
          input
        }
      })
      .then(() => {
        this.method = 'info'
        this.dataDetail()
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_JOBDESCRIPTION(this.templateType),
        variables: {
          jobDescriptionType: this.jobDescriptionType,
          jobDescriptionId: this.jobDescriptionId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    approveData () {
      this.$apollo.mutate({
        mutation: APPROVE_JOBDESCRIPTION(this.templateType),
        variables: {
          jobDescriptionType: this.jobDescriptionType,
          jobDescriptionId: this.jobDescriptionId
        }
      })
      .then(() => {
        this.method = 'info'
        this.dataDetail()
        this.$toasted.global.success("อนุมัติสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    rollbackData () {
      this.$apollo.mutate({
        mutation: ROLLBACK_JOBDESCRIPTION(this.templateType),
        variables: {
          jobDescriptionType: this.jobDescriptionType,
          jobDescriptionId: this.jobDescriptionId
        }
      })
      .then(() => {
        this.method = 'info'
        this.dataDetail()
        this.$toasted.global.success("ยกเลิกสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  watch: {
    selectedTab () {
      this.setRetaining()
    }
  },
  created () {
    if (this.jobDescriptionId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailForm,
    DetailAbility,
    DetailAttendance,
  }
}
</script>

<style lang="css" scoped>
</style>
