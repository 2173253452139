<template>
  <div class="row">
    <div class="col-12">
      <button
        v-show="!isAdd"
        class="btn btn-success mb-3"
        @click="isAdd = !isAdd">
        เพิ่มรายการ
      </button>
    </div>

    <div class="col-12 mb-3" v-if="isAdd">
      <div class="card">
        <DetailAbilityCardForm
          :jobDescriptionId="jobDescriptionId"
          :jobDescriptionType="jobDescriptionType"
          :templateType="templateType"
          @toggle="isAdd = false"
          @update="refetch">
        </DetailAbilityCardForm>
      </div>
    </div>

    <div
      class="col-12 mb-3"
      v-for="ability in abilities"
      :key="ability.id">
      <DetailAbilityCard
        :jobDescriptionId="jobDescriptionId"
        :jobDescriptionType="jobDescriptionType"
        :templateType="templateType"
        :item="ability"
        @update="refetch">
      </DetailAbilityCard>
    </div>
  </div>
</template>

<script>
import DetailAbilityCardForm from './DetailAbilityCardForm'
import DetailAbilityCard from './DetailAbilityCard'
import { LIST_ABILITY } from './graph'

export default {
  props: {
    jobDescriptionId: {
      type: Number,
      required: true
    },
    jobDescriptionType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      isAdd: false
    }
  },
  apollo: {
    abilities: {
      query () {
        return LIST_ABILITY(this.templateType)
      },
      variables () {
        return {
          jobDescriptionType: this.jobDescriptionType,
          jobDescriptionId: this.jobDescriptionId
        }
      }
    }
  },
  methods: {
    refetch () {
      this.$apollo.queries.abilities.refetch()
    }
  },
  components: {
    DetailAbilityCard,
    DetailAbilityCardForm
  }
}
</script>

<style lang="css" scoped>
</style>
