<template>
  <div class="">
    <div
      class="row"
      v-for="tag in tags"
      :key="tag.value">
      <div class="col-12">
        <button
          v-show="!tag.isAdd"
          class="btn btn-success mb-3"
          @click="tag.isAdd = !tag.isAdd">
          {{tag.title}}
        </button>
      </div>

      <div
        class="col-12 mb-3"
        v-if="tag.isAdd">
        <div class="card">
          <DetailAttendanceCardForm
            :tag="tag.value"
            :jobDescriptionId="jobDescriptionId"
            :jobDescriptionType="jobDescriptionType"
            :templateType="templateType"
            @toggle="tag.isAdd = false"
            @update="refetch">
          </DetailAttendanceCardForm>
        </div>
      </div>

      <div
        class="col-12 col-md-6 mb-3"
        v-for="attendance in attendances.filter(att => att.tag === tag.value)"
        :key="attendance.id">
        <DetailAttendanceCard
          :tag="tag.value"
          :jobDescriptionId="jobDescriptionId"
          :jobDescriptionType="jobDescriptionType"
          :templateType="templateType"
          :item="attendance"
          @update="refetch">
        </DetailAttendanceCard>
      </div>
    </div>
  </div>

</template>

<script>
import DetailAttendanceCardForm from './DetailAttendanceCardForm'
import DetailAttendanceCard from './DetailAttendanceCard'
import { LIST_ATTENDANCE } from './graph'

export default {
  props: {
    jobDescriptionId: {
      type: Number,
      required: true
    },
    jobDescriptionType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      tags: [
        {title: 'วันหยุดทั่วไป', value: 'weekend', isAdd: false},
        {title: 'วันหยุดนักขัตฤกษ์', value: 'holiday', isAdd: false},
      ],
      attendances: []
    }
  },
  apollo: {
    attendances: {
      query () {
        return LIST_ATTENDANCE(this.templateType)
      },
      variables () {
        return {
          jobDescriptionType: this.jobDescriptionType,
          jobDescriptionId: this.jobDescriptionId,
          q: {params: {tag: this.tag}}
        }
      }
    }
  },
  methods: {
    refetch () {
      this.$apollo.queries.attendances.refetch()
    }
  },
  components: {
    DetailAttendanceCard,
    DetailAttendanceCardForm
  }
}
</script>

<style lang="css" scoped>
</style>
