<template>
  <div class="">

    <div>
      <sgv-input-text v-model="search" label="ค้นหา"></sgv-input-text>

      <ul>
        <li v-for="item in items" :key="item.id">
          <fa
            icon="check"
            class="text-success pointer"
            @click="addPaper(item)">
          </fa>&nbsp;
          {{item.code}} <span v-show="item.name">({{item.name}})</span>
        </li>
      </ul>
    </div>

    Papers:
    <ul>
      <li v-for="paper in papers" :key="paper.id">
        <fa
          icon="trash"
          class="text-danger pointer"
          @click="deletePaper(paper.id)">
        </fa>&nbsp;
        {{paper.code}} <span v-show="paper.name">({{paper.name}})</span>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  DROPDOWN_PAPERS,
  CREATE_ABILITY_PAPER,
  DESTROY_ABILITY_PAPER
} from './graph'
import debounce from 'lodash/debounce'

export default {
  props: {
    formData: {
      type: Object,
      required: true
    },
    jobDescriptionType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      items: [],
      search: ''
    }
  },
  computed: {
    id () {
      return Number(this.$route.params.id)
    },
    papers () {
      return this.formData.papers.filter(v => {
        const name = v.name || ''
        const isCodeFound = v.code.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        const isNameFound = name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        return isCodeFound || isNameFound
      })
    }
  },
  methods: {
    getList: debounce(function (filter) {
      this.$apollo.query({
        query: DROPDOWN_PAPERS(this.templateType),
        variables: {
          jobDescriptionType: this.jobDescriptionType,
          q: filter
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.items = res.data.papers
      })
      .catch(() => {
        this.items = []
      })
    }, 150),
    addPaper (paper) {
      this.$apollo.mutate({
        mutation: CREATE_ABILITY_PAPER(this.templateType),
        variables: {
          jobDescriptionType: this.jobDescriptionType,
          abilityId: this.formData.id,
          paperId: paper.id
        }
      })
      .then(() => {
        this.addPapers(paper)
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    deletePaper (paperId) {
      this.$apollo.mutate({
        mutation: DESTROY_ABILITY_PAPER(this.templateType),
        variables: {
          jobDescriptionType: this.jobDescriptionType,
          abilityId: this.formData.id,
          paperId
        }
      })
      .then(() => {
        this.removePapers(paperId)
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitPapers (arr) {
      this.$emit('papers', arr)
    },
    addPapers (paper) {
      const index = this.formData.papers.findIndex(v => v.id === paper.id)
      if (index === -1) this.formData.papers.unshift(paper)
    },
    removePapers (paperId) {
      const index = this.formData.papers.findIndex(v => v.id === paperId)
      this.formData.papers.splice(index, 1)
    },
  },
  watch: {
    search (value) {
      if (value) {
        this.getList({
          limit: 20,
          params: {
            search: value
          },
          order: 'code'
        })
      } else {
        this.items = []
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
