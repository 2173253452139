<template>
  <div class="card-body">
    <h6 class="card-title">
      {{item.paper.code}}
      <small
        v-if="$auth.hasRole(`jobDescription:${jobDescriptionType}:add`)"
        class="mx-1 text-warning pointer"
        @click="$emit('toggle', true)">
        <fa icon="pencil-alt"></fa>
      </small>
    </h6>

    <div class="card-subtitle text-muted">
      {{item.paper.name}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailAttendanceCardDetail',
  props: {
    item: {
      type: Object,
      required: true
    },
    jobDescriptionType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
</style>
