<template>
  <div class="card-body">
    <div class="form-row">
      <sgv-input-text
        class="col-12"
        label="ชื่อ"
        v-model="formData.name"
        :validations="[
          {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
        ]">
      </sgv-input-text>

      <sgv-input-number
        class="col-6 col-sm-4"
        label="ระดับ (1-3)"
        v-model="formData.level"
        :validations="[
          {text: 'จำนวนไม่อยู่ในช่วง', value: $v.formData.level.$dirty && !$v.formData.level.between}
        ]">
      </sgv-input-number>

      <sgv-input-number
        class="col-6 col-sm-4"
        label="อายุงาน (เดือน)"
        v-model="formData.monthExperience"
        :validations="[
          {text: 'จำนวนไม่อยู่ในช่วง', value: $v.formData.monthExperience.$dirty && !$v.formData.monthExperience.greater}
        ]">
      </sgv-input-number>

      <sgv-input-text
        class="col-6 col-sm-4"
        label="ทบทวน (cron)"
        v-model="formData.cron">
      </sgv-input-text>

      <sgv-input-textarea
        class="col-12"
        label="รายละเอียด"
        v-model="formData.description"
        :validations="[
          {text: 'required!', value: $v.formData.description.$dirty && !$v.formData.description.required}
        ]">
      </sgv-input-textarea>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          label="บังคับ"
          inline
          v-model="formData.isRequired">
        </sgv-input-check>
      </div>
    </div>

    <DetailAbilityCardFormPaper
      v-if="item"
      class="mb-4"
      :formData="item"
      :jobDescriptionType="jobDescriptionType"
      :templateType="templateType">
    </DetailAbilityCardFormPaper>

    <button
      v-if="!item && $auth.hasRole(`jobDescription:${this.jobDescriptionType}:add`)"
      class="btn btn-success mr-2"
      @click="createData">
      เพิ่ม
    </button>

    <button
      v-if="item && $auth.hasRole(`jobDescription:${this.jobDescriptionType}:add`)"
      class="btn btn-warning mr-2"
      @click="updateData">
      แก้ไข
    </button>

    <button
      class="btn btn-primary "
      @click="clearFormData">
      ยกเลิก
    </button>

    <button
      v-if="item && $auth.hasRole(`jobDescription:${this.jobDescriptionType}:add`)"
      class="btn btn-danger float-right"
      @click="destroyData">
      ลบ
    </button>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailAbilityCardFormPaper from './DetailAbilityCardFormPaper'
import {
  CREATE_ABILITY,
  UPDATE_ABILITY,
  DESTROY_ABILITY
} from './graph'

export default {
  name: 'DetailAbilityCardForm',
  props: {
    jobDescriptionId: {
      type: Number,
      required: true
    },
    jobDescriptionType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      formData: {
        name: '',
        description: '',
        level: 1,
        monthExperience: 0,
        cron: '',
        isRequired: true,
        papers: []
      },
    }
  },
  validations: {
    formData: {
      name: { required },
      description: { required },
      monthExperience: {
        greater (value) {
          return value >= 0 && Number.isInteger(value)
        }
      },
      level: {
        between (value) {
          return value > 0 && value <= 3 && Number.isInteger(value)
        }
      }
    }
  },
  methods: {
    setFormData () {
      this.formData = {
        name: this.item.name,
        description: this.item.description,
        level: this.item.level,
        monthExperience: this.item.monthExperience,
        cron: this.item.cron,
        isRequired: this.item.isRequired,
        papers: this.item.papers
      }
    },
    clearFormData () {
      this.$v.$reset()
      this.formData = {
        name: '',
        description: '',
        level: 1,
        monthExperience: 0,
        cron: '',
        isRequired: true,
        papers: []
      }
      this.emitToggle(false)
    },
    serializeInput (value) {
      const obj = {...value}
      delete obj.papers
      return obj
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_ABILITY(this.templateType),
        variables: {
          jobDescriptionType: this.jobDescriptionType,
          jobDescriptionId: this.jobDescriptionId,
          input: this.serializeInput(this.formData)
        }
      })
      .then(() => {
        this.emitUpdate()
        this.clearFormData()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.mutate({
        mutation: UPDATE_ABILITY(this.templateType),
        variables: {
          jobDescriptionType: this.jobDescriptionType,
          abilityId: this.item.id,
          input: this.serializeInput(this.formData)
        }
      })
      .then(() => {
        this.emitUpdate()
        this.clearFormData()
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_ABILITY(this.templateType),
        variables: {
          jobDescriptionType: this.jobDescriptionType,
          abilityId: this.item.id
        }
      })
      .then(() => {
        this.emitUpdate()
        this.clearFormData()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    emitToggle (value) {
      this.$emit('toggle', value)
    },
    emitUpdate () {
      this.$emit('update', null)
    }
  },
  created () {
    if (this.item) this.setFormData()
  },
  components: {
    DetailAbilityCardFormPaper
  }
}
</script>

<style lang="css">
</style>
