<template>
  <div class="card">
    <component
      :is="toggle ? 'DetailAbilityCardForm' : 'DetailAbilityCardDetail'"
      :jobDescriptionId="jobDescriptionId"
      :jobDescriptionType="jobDescriptionType"
      :templateType="templateType"
      :item="item"
      @toggle="toggle = $event"
      @update="$emit('update', null)">
    </component>
  </div>
</template>

<script>
import DetailAbilityCardForm from './DetailAbilityCardForm'
import DetailAbilityCardDetail from './DetailAbilityCardDetail'

export default {
  props: {
    jobDescriptionId: {
      type: Number,
      required: true
    },
    jobDescriptionType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      toggle: false
    }
  },
  components: {
    DetailAbilityCardForm,
    DetailAbilityCardDetail
  }
}
</script>

<style lang="css" scoped>
</style>
