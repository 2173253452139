<template>
  <div class="card">
    <component
      :tag="tag"
      :is="toggle ? 'DetailAttendanceCardForm' : 'DetailAttendanceCardDetail'"
      :jobDescriptionId="jobDescriptionId"
      :jobDescriptionType="jobDescriptionType"
      :templateType="templateType"
      :item="item"
      @toggle="toggle = $event"
      @update="$emit('update', null)">
    </component>
  </div>
</template>

<script>
import DetailAttendanceCardForm from './DetailAttendanceCardForm'
import DetailAttendanceCardDetail from './DetailAttendanceCardDetail'

export default {
  props: {
    tag: {
      type: String,
      required: true
    },
    jobDescriptionId: {
      type: Number,
      required: true
    },
    jobDescriptionType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      toggle: false
    }
  },
  components: {
    DetailAttendanceCardForm,
    DetailAttendanceCardDetail
  }
}
</script>

<style lang="css" scoped>
</style>
