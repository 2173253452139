<template>
  <div class="card-body">
    <h5 class="card-title">
      {{item.name}}
      <small
        v-if="$auth.hasRole(`jobDescription:${jobDescriptionType}:add`)"
        class="mx-1 text-warning pointer"
        @click="$emit('toggle', true)">
        <fa icon="pencil-alt"></fa>
      </small>
      <small>
        <fa
          icon="arrow-down"
          class="text-primary pointer mx-1 float-right"
          @click="moveAbility(item.id, 1)">
        </fa>
      </small>
      <small>
        <fa
          icon="arrow-up"
          class="text-primary pointer mx-1 float-right"
          @click="moveAbility(item.id, -1)">
        </fa>
      </small>
    </h5>

    <div class="card-subtitle mb-2 text-muted">
      <span
        class="mr-1"
        v-for="i in maxStar"
        :key="i">
        <fa
          icon="star"
          :class="{'text-warning': item.level >= i}">
        </fa>
      </span>

      <span
        class="text-danger"
        v-if="item.isRequired">
        required
      </span>

      <span class="float-right text-info">
        <fa icon="user-clock"></fa>
        {{item.monthExperience}} M
      </span>
    </div>

    <div v-if="item.cron" class="text-small mb-2">
      <div class="" v-if="item.cron">
        ทบทวน (cron): {{item.cron}}
      </div>
    </div>

    <div class="card-text mt-3" style="white-space: pre-line;">
      {{item.description}}
    </div>

    เอกสาร:
    <ul>
      <li v-for="paper in item.papers" :key="paper.id">
        {{paper.code}} ({{paper.name}})
      </li>
    </ul>
  </div>
</template>

<script>
import { MOVE_ABILITY } from './graph'

export default {
  name: 'DetailAbilityCardDetail',
  props: {
    item: {
      type: Object,
      required: true
    },
    jobDescriptionType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      maxStar: 3
    }
  },
  methods: {
    moveAbility (id, step) {
      this.$apollo.mutate({
        mutation: MOVE_ABILITY(this.templateType),
        variables: {
          jobDescriptionType: this.jobDescriptionType,
          abilityId: id,
          step
        }
      })
      .then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.$emit('update', null)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
