<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6"
        label="รหัส"
        v-model="formData.code"
        :validations="[
          {text: 'required!', value: v.formData.code.$dirty && v.formData.code.$error}
        ]">
      </sgv-input-text>

      <DetailFormDropdownParent
        :disabled="$auth.disabled(method)"
        label="parent"
        class="col-6"
        select="id"
        :jobDescriptionType="jobDescriptionType"
        :templateType="templateType"
        v-model="formData.parentId">
      </DetailFormDropdownParent>
    </div>

    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6"
        label="ตำแหน่ง"
        v-model="formData.position"
        :validations="[
          {text: 'required!', value: v.formData.position.$dirty && v.formData.position.$error}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6"
        label="แผนก"
        v-model="formData.department">
      </sgv-input-text>
    </div>

    <button
      type="button"
      class="btn btn-outline-secondary btn-block mb-3 mt-2"
      :class="{'active': toggleDetail}"
      @click="toggleDetail = !toggleDetail">
      รายละเอียดเพิ่มเติม
    </button>

    <template v-if="toggleDetail">
      <div class="form-row">
        <sgv-input-text
          :disabled="$auth.disabled(method)"
          class="col-6"
          label="ผู้บังคับบัญชา"
          v-model="formData.commander">
        </sgv-input-text>

        <sgv-input-text
          :disabled="$auth.disabled(method)"
          class="col-6"
          label="ผู้ใต้บังคับบัญชา"
          v-model="formData.subordinate">
        </sgv-input-text>
      </div>

      <div class="form-row">
        <sgv-input-text
          :disabled="$auth.disabled(method)"
          class="col-6 col-sm-3"
          label="อายุ"
          v-model="formData.age">
        </sgv-input-text>

        <sgv-input-text
          :disabled="$auth.disabled(method)"
          class="col-6 col-sm-3"
          label="เพศ"
          v-model="formData.gender">
        </sgv-input-text>

        <sgv-input-text
          :disabled="$auth.disabled(method)"
          class="col-6 col-sm-3"
          label="วุฒิการศึกษา"
          v-model="formData.education">
        </sgv-input-text>

        <sgv-input-text
          :disabled="$auth.disabled(method)"
          class="col-6 col-sm-3"
          label="สาขา"
          v-model="formData.discipline">
        </sgv-input-text>
      </div>

      <div class="form-row">
        <sgv-input-textarea
          :disabled="$auth.disabled(method)"
          class="col-12 col-md-6"
          label="ความสามารถพิเศษ"
          :rows="1"
          v-model="formData.skill">
        </sgv-input-textarea>

        <sgv-input-textarea
          :disabled="$auth.disabled(method)"
          class="col-12 col-md-6"
          label="อื่นๆ"
          :rows="1"
          v-model="formData.etc">
        </sgv-input-textarea>
      </div>

      <div class="form-row">
        <sgv-input-textarea
          :disabled="$auth.disabled(method)"
          class="col-12 col-md-6"
          label="หน้าที่ความรับผิดชอบ"
          :rows="3"
          v-model="formData.responsibility">
        </sgv-input-textarea>

        <sgv-input-textarea
          :disabled="$auth.disabled(method)"
          class="col-12 col-md-6"
          label="การฝึกอบรมที่จำเป็นต่องาน"
          :rows="3"
          v-model="formData.training">
        </sgv-input-textarea>
      </div>
    </template>

    <div class="form-row">
      <sgv-input-text
        disabled
        class="col-6"
        label="จัดทำ"
        :value="getState('created')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-6"
        label="อนุมัติ"
        :value="getState('approved')">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          :disabled="$auth.disabled(method)"
          label="รับสมัครงาน"
          inline
          v-model="formData.isJobApplication">
        </sgv-input-check>

        <sgv-input-check
          :disabled="$auth.disabled(method)"
          label="ฝึกอบรม"
          inline
          v-model="formData.isJobTraining">
        </sgv-input-check>

        <sgv-input-check
          :disabled="$auth.disabled(method)"
          label="ประเมินผลงาน"
          inline
          v-model="formData.isJobEvaluation">
        </sgv-input-check>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          :disabled="$auth.disabled(method)"
          label="ใช้งาน"
          inline
          v-model="formData.isActive">
        </sgv-input-check>
      </div>
    </div>

  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import DetailFormDropdownParent from './DetailFormDropdownParent.vue'

export default {
  mixins: [retainMixin],
  props: {
    jobDescriptionType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      toggleDetail: false,
      rKey: `ContactJobDescription${this.$form.capitalize(this.jobDescriptionType)}DetailForm`,
    }
  },
  methods: {
    getState (type) {
      const date = this.formData[type + 'At']
      const user = this.formData[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    },
  },
  watch: {
    toggleDetail () {
      this.setRetaining()
    }
  },
  components: {
    DetailFormDropdownParent
  }
}
</script>

<style lang="css">
</style>
