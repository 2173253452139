<template>
  <div class="card-body">
    <div class="form-row">
      <DetailAttendanceCardFormPaper
        class="col-12"
        label="แผนกิจกรรม"
        v-model="formData.paperId"
        :jobDescriptionType="jobDescriptionType"
        :templateType="templateType"
        select="id"
        :validations="[
          {text: 'required!', value: $v.formData.paperId.$dirty && !$v.formData.paperId.required}
        ]">
      </DetailAttendanceCardFormPaper>
    </div>

    <button
      v-if="!item && $auth.hasRole(`jobDescription:${jobDescriptionType}:add`)"
      class="btn btn-success mr-2"
      @click="createData">
      เพิ่ม
    </button>

    <button
      v-if="item && $auth.hasRole(`jobDescription:${jobDescriptionType}:add`)"
      class="btn btn-warning mr-2"
      @click="updateData">
      แก้ไข
    </button>

    <button
      class="btn btn-primary "
      @click="clearFormData">
      ยกเลิก
    </button>

    <button
      v-if="item && $auth.hasRole(`jobDescription:${jobDescriptionType}:add`)"
      class="btn btn-danger float-right"
      @click="destroyData">
      ลบ
    </button>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailAttendanceCardFormPaper from './DetailAttendanceCardFormPaper'
import {
  CREATE_ATTENDANCE,
  UPDATE_ATTENDANCE,
  DESTROY_ATTENDANCE
} from './graph'

export default {
  name: 'DetailAttendanceCardForm',
  props: {
    tag: {
      type: String,
      required: true
    },
    jobDescriptionId: {
      type: Number,
      required: true
    },
    jobDescriptionType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      formData: {
        paperId: null,
        tag: this.tag
      },
    }
  },
  validations: {
    formData: {
      paperId: { required }
    }
  },
  methods: {
    setFormData () {
      this.formData = {
        paperId: this.item.paperId,
      }
    },
    clearFormData () {
      this.$v.$reset()
      this.formData = {
        paperId: null
      }
      this.emitToggle(false)
    },
    serializeInput (value) {
      return {...value}
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_ATTENDANCE(this.templateType),
        variables: {
          jobDescriptionType: this.jobDescriptionType,
          jobDescriptionId: this.jobDescriptionId,
          input: this.serializeInput(this.formData)
        }
      })
      .then(() => {
        this.emitUpdate()
        this.clearFormData()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.mutate({
        mutation: UPDATE_ATTENDANCE(this.templateType),
        variables: {
          jobDescriptionType: this.jobDescriptionType,
          jobDescriptionPaperJunctionId: this.item.id,
          input: this.serializeInput(this.formData)
        }
      })
      .then(() => {
        this.emitUpdate()
        this.clearFormData()
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_ATTENDANCE(this.templateType),
        variables: {
          jobDescriptionType: this.jobDescriptionType,
          jobDescriptionPaperJunctionId: this.item.id
        }
      })
      .then(() => {
        this.emitUpdate()
        this.clearFormData()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    emitToggle (value) {
      this.$emit('toggle', value)
    },
    emitUpdate () {
      this.$emit('update', null)
    }
  },
  created () {
    if (this.item) this.setFormData()
  },
  components: {
    DetailAttendanceCardFormPaper
  }
}
</script>

<style lang="css">
</style>
